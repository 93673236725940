import React from 'react';
import { useTranslation } from 'react-i18next';

interface SupportHelp {
  theme: string;
}

function SupportHelp({ theme }: SupportHelp) {
  const { t } = useTranslation();
  return (
    <div className={`supportLink_bg_${theme} supportLink_container`}>
      <p className={`text_colour_${theme} supportLink_text`}>
        {t('ms-teams-for-help-1')}
        <span>
          {' '}
          <a
            href="https://support.insights.com/microsoft-teams"
            id="supportLink"
            target="_blank"
            className={`supportLink_text avatarLink_${theme}`}
            rel="noreferrer"
          >
            {t('ms-teams-for-help-2')}
          </a>
        </span>
      </p>
    </div>
  );
}

export default SupportHelp;
