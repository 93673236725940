import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { hotjar } from 'react-hotjar';
import { dialog, app } from '../services/ms-teams-service';
import { addProfileView, getSignedInUser } from '../services/learnersService';
import { UserContext } from '../context/user-context';

interface CompareProfileProps {
  userEmail: string;
  theme: string;
}

function CompareProfile({ userEmail, theme }: CompareProfileProps) {
  const { t } = useTranslation();
  const context = useContext(UserContext) || { themeState: 'default' };

  const handleModal = async (email: string) => {
    const dialogInfo = {
      title: t('ms-teams-compare-profile-button-text'),
      url: `${
        window.location.origin
      }/compare-profiles?emails=${encodeURIComponent(email.toString())}`,
      size: {
        width: 900,
        height: 525,
      },
    };

    hotjar.event('compare-profile');
    dialog.url.open(dialogInfo);

    const teamsAppData = await app.getContext();

    let signedInUserEmail = teamsAppData.user?.userPrincipalName || context?.user?.email || '';

    const tenantId = context.user?.tenantId || teamsAppData.user?.tenant?.id;

    if (!signedInUserEmail && tenantId) {
      // backup - check graph for current user if all others fail.
      const { data } = await getSignedInUser(tenantId);
      signedInUserEmail = data?.userPrincipalName;
    }

    // Save profile view to show on dashboard
    addProfileView(signedInUserEmail, userEmail);
  };

  return (
    <div style={{ marginTop: 6 }}>
      <button
        type="button"
        className={`w-100 mt-1 meetingButton meetingButton_${theme}`}
        onClick={() => handleModal(userEmail)}
      >
        {t('ms-teams-compare-profile-button-text')}
      </button>
    </div>
  );
}

export default CompareProfile;
