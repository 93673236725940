/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../context/user-context';
import { getTextLabelColour } from '../../../util/theme';

interface InfoCardProps {
  isLinkDisabled: boolean;
  icon: string;
  title: string;
  description: string;
  isProfileSet: boolean;
  whereText?: string;
  whereSubtext?: string;
  whereLink?: string;
  whereButtonAction?: any;
  whereId?: string | null;
}

function InfoCard({
  isLinkDisabled,
  icon,
  title,
  description,
  isProfileSet = false,
  whereText = '',
  whereSubtext = '',
  whereLink = '',
  whereButtonAction = null,
  whereId = null,
}: InfoCardProps) {
  const { t } = useTranslation();
  const context = useContext(UserContext) || { themeState: 'default' };
  const style = isProfileSet ? 'profileSet' : 'noProfileSet';

  return (
    <div className={`${style}InfoSectionContainer col-12 col-sm-6 col-lg-4`}>
      <div className={`${style}InfoItemContainer`}>
        <img
          src={icon}
          alt={`${style}SetInfoSectionContainerIcon`}
          className="iconImage"
        />
        <h3
          className={`${style}InfoItemTitle text_colour_${context.themeState}`}
        >
          {title}
        </h3>
        <p className={`${context.themeState}_sub_title`}>{description}</p>
        {whereText ? (
          <div className="d-flex flex-row">
            <p
              className={`where_text text_colour_${context.themeState}`}
              style={{ marginRight: 8 }}
            >
              {' '}
              {t('ms-teams-info-section-where')}
              {' '}
            </p>
            <div className="d-flex flex-column">
              <p className={`where_text text_colour_${context.themeState}`}>
                {whereLink && whereId ? (
                  <a
                    href={`#${whereLink}`}
                    className={`avatarLink_${context.themeState}`}
                    id={whereId}
                  >
                    {' '}
                    {whereText}
                  </a>
                ) : !isLinkDisabled ? (
                  <button
                    type="button"
                    className="personal_dashboard_button"
                    onClick={whereButtonAction}
                    style={{
                      color: getTextLabelColour(context.themeState),
                    }}
                  >
                    {whereText}
                  </button>
                ) : (
                  <p className={`where_text avatarParagraph_${context.themeState}`}>{whereText}</p>
                )}
              </p>
              {whereSubtext ? (
                <p
                  className={`where_text avatarParagraph_${context.themeState}`}
                >
                  {whereSubtext}
                </p>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default InfoCard;
