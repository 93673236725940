export const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URL;
export const GET_SIGNED_IN_USER = 'api/learners/me';
export const GET_SIGNED_IN_USER_PHOTO = 'api/learners/me/photo';
export const UPDATE_USER_PHOTO = 'api/learners/me/photo';
export const GET_PEOPLE = 'api/learners/people';
export const GET_USER = 'api/learners/user';
export const POST_COMPARE_INFO = 'api/learners/compare-profiles/info';
export const GET_CHAT_INFO = 'api/learners/chatInfo';
export const GET_CHANNEL_MEMBERS = 'api/learners/channel';
export const GET_PRESENCE = 'api/learners/presence';
export const GET_MEETINGS = 'api/learners/meetings';
export const POST_INSTALL_MEETING_TAB = 'api/learners/install-meeting-tab';
export const POST_ALLOW_SHARE_COLOUR_ENERGIES = 'api/learners/me/allow-share-colour-energies';
export const POST_CONFIRM_COLOUR_ENERGIES = 'api/learners/me/set-colour-energies';
export const GET_LEARNER_RECORD = 'api/learners/me/learner-record';
export const PUT_LEARNER_PROFILE_DATE = 'api/learners/me/learner-profile-date';
export const PUT_LEARNER_LNG_CODE = 'api/learners/me/set-lng-code';
export const GET_USER_SETTINGS = 'api/learners/me/settings';
export const POST_UPDATE_USER_SETTINGS = 'api/learners/me/update-user-settings';
export const POST_UPDATE_USER_STATEMENT_SETTINGS = 'api/learners/me/update-user-statement-settings';
export const POST_ADD_NOMINATED_USER = 'api/learners/me/nominate-user';
export const GET_LEARNER_INFO = 'api/learners/dashboard/info';
export const GET_TRANSLATIONS = 'api/translations';
export const SEND_EMAIL = 'api/email/send';
export const POST_BOT_MESSAGE = 'api/notify';
export const POST_FEEDBACK_MESSAGE = 'api/feedback';
export const POST_SUPPORT_REQUEST_MESSAGE = 'api/support-request';
export const ATTACH_PEOPLE_AVATAR_AND_PRESENCE = 'api/learners/attach-profile-photos-presences';
export const ADD_PROFILE_VIEW = 'api/learners/compare-profiles/add-view';
export const DELETE_USER = 'api/learners/me/delete';
export const POST_RESET_STATEMENTS_REQUEST_MESSAGE = 'api/reset-statements';
