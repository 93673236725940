import React from 'react';
import { useTranslation } from 'react-i18next';
import InsightsForWeekTile from '../common/InsightsForWeekTile/InsightsForWeekTile';
import { InsightsForThisWeekItem } from '../../global/types';

interface InsightsForTheWeek {
  theme: string;
  data: Array<InsightsForThisWeekItem> | null;
}

function InsightsForTheWeek({ theme, data }: InsightsForTheWeek) {
  const { t } = useTranslation();

  const getInsightsForThisWeekTitle = (id: string) => {
    switch (id) {
      case 'BLIND_SPOTS':
        return t('ms-teams-insights-week-section-title-your-challenge');
      case 'SUGGESTIONS_FOR_DEVELOPMENT':
        return t('ms-teams-insights-week-section-title-your-suggestion');
      case 'VALUE_TO_THE_TEAM':
        return t('ms-teams-insights-week-section-title-your-value');
      case 'CREATING_THE_IDEAL_ENVIRONMENT':
        return t('ms-teams-insights-week-section-title-your-ideal-environment');
      case 'MOTIVATING':
        return t('ms-teams-insights-week-section-title-your-motivator');
      case 'BEFORE_THE_SALE_BEGINS_SUGGESTIONS':
        return t('ms-teams-insights-week-section-title-your-suggestion-sale');
      case 'PROPOSING_SUGGESTIONS':
        return t(
          'ms-teams-insights-week-section-title-your-suggestion-proposing',
        );
      case 'TIME_AND_LIFE_MANAGEMENT':
        return t('ms-teams-insights-week-section-title-your-time-management');
      case 'PERSONAL_CREATIVITY':
        return t(
          'ms-teams-insights-week-section-title-your-creative-development',
        );
      default:
        return '';
    }
  };

  return (
    <div className={`insights_week_container_${theme}`} id="insightsWeekly">
      <p
        className={`insights_week_container_header insights_week_container_header_${theme}`}
      >
        {t('ms-teams-insights-week-title')}
      </p>
      <table className="jump-to-table">
        <tbody>
          <tr>
            <div
              className="row row-flex insights_week_section"
              id="InsightsForWeekContainer"
            >
              {(data || []).map((item) => (
                <div
                  key={`${item.id}_${item.text}`}
                  className="col-xs-12 insights_weekly_padding"
                >
                  <div
                    className={`insights_week_section_content insights_week_section_content_${theme}`}
                  >
                    <InsightsForWeekTile
                      title={getInsightsForThisWeekTitle(item.id)}
                      description={item.text}
                      theme={theme}
                      isHighlighted={false}
                    />
                  </div>
                </div>
              ))}
            </div>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default InsightsForTheWeek;
