import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SetupLogo } from '../../images/SetupLogo.svg';
import { ReactComponent as SetupLogoDark } from '../../images/SetupLogoDark.svg';

interface PoweredByInsights {
  theme: string;
}

function PoweredByInsights({ theme }: PoweredByInsights) {
  const { t } = useTranslation();
  return (
    <>
      <div className="noProfileInfoSetSpacer" />
      <div className="col-10 noProfileSetInfoSectionFooter">
        <h6
          id="footerTitle"
          className={`${theme}_sub_title`}
          style={{ marginBottom: 7 }}
        >
          {t('ms-teams-insights-no-colour-energy-profile-footer-powered-by')}
        </h6>
        {theme === 'default' ? <SetupLogo /> : <SetupLogoDark />}
        <p
          className={`noProfileSetInfoSectionTitle text_colour_${theme}`}
          style={{ marginTop: 20 }}
        >
          {t('ms-teams-insights-no-colour-energy-profile-footer-title')}
        </p>
        <p className={`${theme}_sub_title`}>
          {t('ms-teams-insights-no-colour-energy-profile-footer-description')}
        </p>
        <a
          id="footerLinkBtn"
          href="https://www.insights.com/"
          target="_blank"
          className={`secondaryButton_${theme} sub_${theme}_title mb-3 api_content_heading_default`}
          style={{
            paddingTop: 6,
            paddingBottom: 6,
          }}
          rel="noreferrer"
        >
          {t('ms-teams-insights-no-colour-energy-profile-footer-button')}
        </a>
      </div>
    </>
  );
}

export default PoweredByInsights;
