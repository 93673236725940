// import { localeCodeToPrivacyURL } from './mapLanguageCode';

const handlePrivacyLink = async () => {
  // Add the below back after beta:
  // const teamsAppData = await app.getContext();
  // const languageCode = teamsAppData.app.locale;
  // const urlSlug = localeCodeToPrivacyURL(languageCode);
  // const url = `https://www.insights.com/${urlSlug}`;

  const url = 'https://www.insights.com/ms-teams-privacy-policy/';

  return url;
};

export default handlePrivacyLink;
