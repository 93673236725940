/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar } from '@fluentui/react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TeamsButton from '../common/TeamsButton';

interface NoSetupProfileProps {
  theme: string;
  handleModal: any;
  displayName: string;
  photo: string;
}

function NoSetupProfile({
  theme,
  displayName,
  handleModal,
  photo,
}: NoSetupProfileProps) {
  const { t } = useTranslation();
  return (
    <div
      className={`bg_${theme}_card border_${theme} pb-5 pt-4 summary_section_shadow card_padding mt-0 no_setup_profile d-flex flex-column justify-content-center align-items-center col-lg-7 col-md-10 col-12`}
    >
      <div>
        <div className="color-wheel">
          <div className="color-circle">
            <div className="inner-circle">
              {' '}
              <Avatar
                image={{ src: photo }}
                name={displayName}
                size={72}
                style={{ width: '100px', height: '100px' }}
              />
            </div>
          </div>
          <div className="overlay" />
        </div>
      </div>
      <h3
        className={`text_colour_${theme} insightsNoProfileTitle`}
        id="noProfileSetupTitle"
      >
        {t('ms-teams-insights-no-colour-energy-profile-card-title')}
      </h3>
      <p
        className={`${theme}_sub_title col-lg-6`}
        style={{ textAlign: 'center' }}
      >
        {t('ms-teams-insights-no-colour-energy-profile-card-description')}
      </p>
      <div className="noProfileButtonContainer" style={{ marginTop: 'auto' }}>
        <TeamsButton
          id="confirmProfileModalBtn"
          btnText={t('ms-teams-insights-no-colour-energy-profile-card-button')}
          onClick={handleModal}
          theme={theme}
        />
      </div>
    </div>
  );
}

export default NoSetupProfile;
