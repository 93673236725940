/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { ColourEnergyValues } from '../../../global/types';
import FileUpload from '../FileUpload';

interface DonutProps {
  btnText: string;
  src: string;
  values: ColourEnergyValues;
  greyscale: boolean;
  fileUploadFunction: any;
  theme: string;
  tabWidth: number
}

ChartJS.register(ArcElement, Tooltip);

const options = {
  legend: {
    display: false,
  },
  cutout: 150,
  // hover: { mode: null }, <-- remove hover
  plugins: {
    tooltip: {
      enabled: false,
    },
  },
  maintainAspectRatio: true,
  // maintainAspectRatio: false,
};

function DonutChart({
  btnText,
  src,
  values,
  greyscale,
  fileUploadFunction,
  theme,
  tabWidth,
}: DonutProps) {
  const data = {
    datasets: [
      {
        data: [
          values.fieryRedEnergy,
          values.sunshineYellowEnergy,
          values.earthGreenEnergy,
          values.coolBlueEnergy,
        ],
        backgroundColor: [
          '#DB2F41', // red
          '#FFD74B', // yellow
          '#3B854B', // green
          '#0071BB', // blue
        ],
        borderWidth: 0,
      },
    ],
  };

  let svgDimension = '238';
  let circleDimension = '120';
  let svgViewBox = '0 0 238 238';
  let circleR = '117';

  if (tabWidth < 600) {
    options.cutout = 105;
    svgDimension = '213';
    circleDimension = '109';
    svgViewBox = '0 0 213 213';
    circleR = '107';
  }

  return (
    <div className="donutContainer" id="avatarImage">
      {src ? (
        <img
          alt="Avatar"
          className="image-avatar"
          id="uploadedAvatarImage"
          src={src}
          style={{
            filter: greyscale ? 'grayscale(100%)' : '',
          }}
        />
      ) : (
        <div className="uploadPhotoButttonContainer">
          {tabWidth >= 600 ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={svgDimension}
              height={svgDimension}
              viewBox={svgViewBox}
              fill="none"
              style={{ position: 'absolute', zIndex: -1 }}
            >
              <circle
                cx={circleDimension}
                cy={circleDimension}
                r={circleR}
                stroke="#CCCCCC"
                strokeWidth="2"
                strokeDasharray="10 10"
              />
            </svg>
          ) : (
            null
          )}
          <FileUpload
            fileUploadFunction={fileUploadFunction}
            btnText={btnText}
            theme={theme}
          />
        </div>
      )}
      <Doughnut data={data} options={options} className="donutChart" />
    </div>
  );
}

export default DonutChart;
