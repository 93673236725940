import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTextLabelColour } from '../../../util/theme';
import { StatementEnhanced } from '../../../global/types';

interface HighlightsProps {
  id?: string;
  theme: string;
  values: StatementEnhanced[];
}

function Highlights({ id, theme, values = [] }: HighlightsProps) {
  const { t } = useTranslation();

  if (values.length === 0) {
    return (
      <div className="highlight_container" id={id}>
        <h3
          className={`section_title avatarParagraph_${theme} box_border_${theme} api_content_heading_default`}
          id="highlightsTitle"
        >
          {t('ms-teams-personal-profile-highlights')}
        </h3>
        <p className={`avatarTitle_${theme} customise_row_section_title`}>
          {t('ms-teams-profile-no-statements')}
        </p>
        <div
          className={`personal_profile_spacer personal_profile_spacer_${theme}`}
        />
      </div>
    );
  }

  const pickNewHighlight = (curValue: StatementEnhanced) => {
    let newHighlight = curValue;
    while (newHighlight.text === curValue.text) {
      const randomIndex = Math.floor(Math.random() * values.length);
      newHighlight = values[randomIndex];
    }

    return newHighlight;
  };

  const [curHighlight, setCurHighlight] = useState(values[0]);

  const showAnother = () => setCurHighlight(pickNewHighlight(curHighlight));

  return (
    <div className="highlight_container" id={id}>
      <h3
        id="highlightsTitle"
        className={`section_title avatarParagraph_${theme} box_border_${theme} api_content_heading_default`}
      >
        {t('ms-teams-personal-profile-highlights')}
      </h3>
      <p
        className={`avatarTitle_${theme} customise_row_section_title`}
        style={{ marginBottom: 6 }}
        id="highlightsText"
      >
        {curHighlight.text}
      </p>
      {values.length > 1 ? (
        <button
          type="button"
          onClick={showAnother}
          className=" personal_dashboard_button"
          style={{ color: getTextLabelColour(theme) }}
          id="highlightsShowAnotherBtn"
        >
          {t('ms-teams-personal-profile-show-another')}
        </button>
      ) : null}
      <div
        className={`personal_profile_spacer personal_profile_spacer_${theme}`}
      />
    </div>
  );
}

export default Highlights;
