import { app } from '../services/ms-teams-service';
import { getLearnerRecord, putLearnerLngCode } from '../services/learnersService';

export default async function checkLanguageChange() {
  const teamsAppData = await app.getContext();
  const lng = teamsAppData.app.locale;

  const lngCode = lng && lng.slice(0, 2);

  const email = teamsAppData.user?.userPrincipalName;

  if (email) {
    const { data } = await getLearnerRecord(email);
    const dbLngEntry = data?.lng_code;

    if (dbLngEntry !== lngCode) {
      await putLearnerLngCode(email, lngCode);
    }
  }
}
