/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/user-context';
// Images
import { ReactComponent as ColourMatch } from '../images/preferences/ColourMatch.svg';
import { ReactComponent as ColourMatchDark } from '../images/preferences/ColourMatchDark.svg';
import { ReactComponent as ColourMatchContrast } from '../images/preferences/ColourMatchContrast.svg';
import { ReactComponent as FieryRed } from '../images/preferences/FieryRed.svg';
import { ReactComponent as FieryRedDark } from '../images/preferences/FieryRedDark.svg';
import { ReactComponent as FieryRedContrast } from '../images/preferences/FieryRedContrast.svg';
import { ReactComponent as CoolBlue } from '../images/preferences/CoolBlue.svg';
import { ReactComponent as CoolBlueDark } from '../images/preferences/CoolBlueDark.svg';
import { ReactComponent as CoolBlueContrast } from '../images/preferences/CoolBlueContrast.svg';
import { ReactComponent as EarthGreen } from '../images/preferences/EarthGreen.svg';
import { ReactComponent as EarthGreenDark } from '../images/preferences/EarthGreenDark.svg';
import { ReactComponent as EarthGreenContrast } from '../images/preferences/EarthGreenContrast.svg';
import { ReactComponent as SunshineYellow } from '../images/preferences/SunshineYellow.svg';
import { ReactComponent as SunshineYellowDark } from '../images/preferences/SunshineYellowDark.svg';
import { ReactComponent as SunshineYellowContrast } from '../images/preferences/SunshineYellowContrast.svg';
import { ReactComponent as Opposites } from '../images/preferences/Opposites.svg';
import { ReactComponent as OppositesDark } from '../images/preferences/OppositesDark.svg';
import { ReactComponent as OppositesContrast } from '../images/preferences/OppositesContrast.svg';
import ProfileBreakdown from './common/ProfileBreakdown';
import {
  getSignedInUserPhoto,
  getUser,
  postComparisonInfo,
} from '../services/learnersService';
import {
  CompareProfileOutput,
  CompareProfileUser,
  Person,
  User,
} from '../global/types';
import Spinner from './common/Spinners/Spinner';
// import MainSpinner from './common/Spinners/MainSpinner';

type UserState = {
  user: Person | null;
  error: number | null;
};

function getCompareProfileImage(key: string, themeState: string) {
  // scenario 1
  if (key === 'Same_energies') {
    return themeState === 'default'
      ? [<ColourMatch />]
      : themeState === 'dark'
        ? [<ColourMatchDark />]
        : [<ColourMatchContrast />];
  }

  // scenarios 2, 6, 7 and 8 - fieryRed image
  if (
    key === 'Lead_with_same_energy_fieryRedEnergy'
    || key === 'Both_learners_have_one_of_the_same_colours_above_the_line_red'
    || key
      === 'One_learners_lead_colour_is_the_other_learners_lower_preference_colours_fieryRedEnergy'
    || key === 'Both_learners_have_the_same_lowest_colour_energy_fieryRedEnergy'
  ) {
    return themeState === 'default'
      ? [<FieryRed />]
      : themeState === 'dark'
        ? [<FieryRedDark />]
        : [<FieryRedContrast />];
  }

  // scenarios 2, 6, 7 and 8 - coolBlue image
  if (
    key === 'Lead_with_same_energy_coolBlueEnergy'
    || key === 'Both_learners_have_one_of_the_same_colours_above_the_line_blue'
    || key
      === 'One_learners_lead_colour_is_the_other_learners_lower_preference_colours_coolBlueEnergy'
    || key === 'Both_learners_have_the_same_lowest_colour_energy_coolBlueEnergy'
  ) {
    return themeState === 'default'
      ? [<CoolBlue />]
      : themeState === 'dark'
        ? [<CoolBlueDark />]
        : [<CoolBlueContrast />];
  }

  // scenarios 2, 6, 7 and 8 - earthGreen image
  if (
    key === 'Lead_with_same_energy_earthGreenEnergy'
    || key === 'Both_learners_have_one_of_the_same_colours_above_the_line_green'
    || key
      === 'One_learners_lead_colour_is_the_other_learners_lower_preference_colours_earthGreenEnergy'
    || key === 'Both_learners_have_the_same_lowest_colour_energy_earthGreenEnergy'
  ) {
    return themeState === 'default'
      ? [<EarthGreen />]
      : themeState === 'dark'
        ? [<EarthGreenDark />]
        : [<EarthGreenContrast />];
  }

  // scenarios 2, 6, 7 and 8 - sunshineYellow image
  if (
    key === 'Lead_with_same_energy_sunshineYellowEnergy'
    || key
      === 'Both_learners_have_one_of_the_same_colours_above_the_line_yellow'
    || key
      === 'One_learners_lead_colour_is_the_other_learners_lower_preference_colours_sunshineYellowEnergy'
    || key
      === 'Both_learners_have_the_same_lowest_colour_energy_sunshineYellowEnergy'
  ) {
    return themeState === 'default'
      ? [<SunshineYellow />]
      : themeState === 'dark'
        ? [<SunshineYellowDark />]
        : [<SunshineYellowContrast />];
  }

  // scenarios 3
  if (key === 'Both_learners_are_the_same_creative_type_red_green') {
    return themeState === 'default'
      ? [<FieryRed />, <EarthGreen />]
      : themeState === 'dark'
        ? [<FieryRedDark />, <EarthGreenDark />]
        : [<FieryRedContrast />, <EarthGreenContrast />];
  }

  if (key === 'Both_learners_are_the_same_creative_type_blue_yellow') {
    return themeState === 'default'
      ? [<CoolBlue />, <SunshineYellow />]
      : themeState === 'dark'
        ? [<CoolBlueDark />, <SunshineYellowDark />]
        : [<CoolBlueContrast />, <SunshineYellowContrast />];
  }

  // scenarios 4
  if (
    key === 'Both_learners_have_two_of_the_same_colours_above_the_line_red_blue'
  ) {
    return themeState === 'default'
      ? [<FieryRed />, <CoolBlue />]
      : themeState === 'dark'
        ? [<FieryRedDark />, <CoolBlueDark />]
        : [<FieryRedContrast />, <CoolBlueContrast />];
  }

  if (
    key
    === 'Both_learners_have_two_of_the_same_colours_above_the_line_red_green'
  ) {
    return themeState === 'default'
      ? [<FieryRed />, <EarthGreen />]
      : themeState === 'dark'
        ? [<FieryRedDark />, <EarthGreenDark />]
        : [<FieryRedContrast />, <EarthGreenContrast />];
  }

  if (
    key
    === 'Both_learners_have_two_of_the_same_colours_above_the_line_red_yellow'
  ) {
    return themeState === 'default'
      ? [<FieryRed />, <SunshineYellow />]
      : themeState === 'dark'
        ? [<FieryRedDark />, <SunshineYellowDark />]
        : [<FieryRedContrast />, <SunshineYellowContrast />];
  }

  if (
    key
    === 'Both_learners_have_two_of_the_same_colours_above_the_line_blue_green'
  ) {
    return themeState === 'default'
      ? [<CoolBlue />, <EarthGreen />]
      : themeState === 'dark'
        ? [<CoolBlueDark />, <EarthGreenDark />]
        : [<CoolBlueContrast />, <EarthGreenContrast />];
  }

  if (
    key
    === 'Both_learners_have_two_of_the_same_colours_above_the_line_blue_yellow'
  ) {
    return themeState === 'default'
      ? [<CoolBlue />, <SunshineYellow />]
      : themeState === 'dark'
        ? [<CoolBlueDark />, <SunshineYellowDark />]
        : [<CoolBlueContrast />, <SunshineYellowContrast />];
  }

  if (
    key
    === 'Both_learners_have_two_of_the_same_colours_above_the_line_green_yellow'
  ) {
    return themeState === 'default'
      ? [<EarthGreen />, <SunshineYellow />]
      : themeState === 'dark'
        ? [<EarthGreenDark />, <SunshineYellowDark />]
        : [<EarthGreenContrast />, <SunshineYellowContrast />];
  }

  // scenarios 5
  if (key === 'Each_learners_colour_orders_are_exact_opposites_of_each_other') {
    return themeState === 'default'
      ? [<Opposites />]
      : themeState === 'dark'
        ? [<OppositesDark />]
        : [<OppositesContrast />];
  }

  return [];
}

function CompareProfiles() {
  const context = useContext(UserContext) || { themeState: 'default' };
  const [comparisonInfo, setComparisonInfo] = useState<CompareProfileOutput | null>(null);
  const [loadingUser, setIsLoadingUser] = useState(false);
  const [userState, setUserState] = useState<UserState | null>({
    user: null,
    error: null,
  });
  const [photo, setPhoto] = useState<string>('');

  /**
   * Retrieves the search param for the compared
   * users profile email address.
   *
   * @returns the user email or invalid
   */
  const getComparedUsersEmail = () => {
    const search = new URLSearchParams(window.location.search);
    const userEmail = search.get('emails');
    const emailRegex = /^[\w.%+-]+@[\da-z.-]+\.[a-z]{2,}$/i; // check valid email address

    if (emailRegex.test(userEmail || '')) {
      return userEmail as string;
    }

    return 'invalid';
  };

  const fetchComparableProfileData = async () => {
    setIsLoadingUser(true);
    if (context && context?.updateUser) {
      await context?.updateUser();
      const result = await getUser(getComparedUsersEmail());
      if (result.status === 200) {
        const d = (result.data as Person[])[0];
        setUserState({ user: d as Person, error: null });
      } else {
        setUserState({ user: null, error: result.status || null });
      }
    }
    setIsLoadingUser(false);

    const photoResult = await getSignedInUserPhoto();

    if (photoResult.status === 200) {
      setPhoto(photoResult.data as string);
    }
  };

  const constructData = (p: User | Person): CompareProfileUser => ({
    order: p.consciousColourEnergy,
    percentages: p.consciousColourEnergyValues,
  });

  const fetchComparisonData = async () => {
    if (userState?.user && context.user) {
      const [p1, p2] = [
        constructData(userState.user),
        constructData(context.user as User),
      ];

      const comparisonData = await postComparisonInfo(p1, p2);
      setComparisonInfo(comparisonData.data as CompareProfileOutput);
    }
  };

  // Fetch the User info + Compared user info
  useEffect(() => {
    fetchComparableProfileData();
  }, []);

  useEffect(() => {
    fetchComparisonData();
  }, [context, userState]);

  if (loadingUser) {
    return (
      <div
        className={`bg_${context.themeState}_card`}
        style={{ height: '100vh' }}
      >
        <Spinner theme={context.themeState} animationName="spin" />
      </div>
    );
  }

  return (
    <div
      className={`d-flex flex-column compare_profile_outer_border_${context.themeState} bg_${context.themeState}_card`}
      style={{ borderWidth: 0, borderTopWidth: 2, borderStyle: 'solid' }}
    >
      {/* Preference statement */}
      {comparisonInfo !== null ? (
        <div
          className={`rainbow_border_${context.themeState} border_radius_rainbow preference_statement bg_${context.themeState}_card d-flex flex-sm-row flex-column-reverse justify-content-between`}
          style={{ height: 'auto' }}
        >
          {/* <div className="d-flex flex-column" style={{ maxWidth: '80%' }}> */}
          <div className="d-flex flex-column w-md-80 w-100">
            <p
              className={`avatarTitle_${context.themeState} mt-md-0 mt-2`}
              style={{
                marginBottom: 3,
                fontWeight: 600,
                fontSize: 14,
              }}
            >
              {comparisonInfo.title}
            </p>
            <p
              className={`personal_profile_subtext avatarTitle_${context.themeState}`}
              style={{ marginBottom: 0, fontWeight: 400, fontSize: 14 }}
            >
              {comparisonInfo.description}
            </p>
          </div>
          <div className="d-flex gap-1">
            {getCompareProfileImage(comparisonInfo.key, context.themeState).map(
              (img) => img,
            )}
          </div>
        </div>
      ) : (
        <p> Nothing to show yet</p>
      )}

      {/* Comparisons */}
      {context && context.user && userState && userState.user ? (
        <div
          className={`d-flex flex-column flex-md-row compare_profile_outer_border_${context.themeState} compare_border_top`}
        >
          <ProfileBreakdown
            createdAt={context.user?.createdAt}
            displayName={context.user.displayName}
            photo={photo}
            colourEnergyValues={context.user?.consciousColourEnergyValues}
            colourEnergyOrder={context.user?.consciousColourEnergy}
            fullProfile={context.user.fullProfile}
            theme={context.themeState}
            yourProfile
            highlights={context.user.highlights}
            strengths={context.user.strengths}
            weakness={context.user.weakness}
            communicationDos={context.user.communicationDos}
            communicationDonts={context.user.communicationDonts}
            isCurrentUser
          />
          <div
            className={`compare_profile_outer_border_${context.themeState}`}
            style={{
              borderWidth: 0,
              borderRightWidth: 1,
              borderStyle: 'solid',
            }}
          />
          <ProfileBreakdown
            createdAt={userState.user.createdAt || ''}
            displayName={userState.user.displayName}
            photo={`data:image/jpeg;base64, ${userState.user.profilePhoto}`}
            colourEnergyValues={userState.user.consciousColourEnergyValues}
            colourEnergyOrder={userState.user.consciousColourEnergy}
            fullProfile={context.user.fullProfile}
            theme={context.themeState}
            yourProfile={false}
            highlights={userState.user.highlights}
            strengths={userState.user.strengths}
            weakness={userState.user.weakness}
            communicationDos={userState.user.communicationDos}
            communicationDonts={userState.user.communicationDonts}
            isCurrentUser={false}
          />
        </div>
      ) : null}
    </div>
  );
}

export default CompareProfiles;
