/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PersonCard from '../common/PersonCard/PersonCard';
import { Person, ColourEnergyValues } from '../../global/types';
import Error from '../common/Error/Error';
import CustomToggle from '../common/Toggle';

interface SummaryCardProps {
  loggedInUserName: string;
  loggedInUserConfirmedDate: string;
  loggedInUserColourEnergy: ColourEnergyValues;
  title: string;
  subtext: string;
  people: Array<Person> | [];
  error: number | null;
  onSuccessConsent(): void;
  theme?: string;
  conscious?: boolean;
  onConsciousToggleClicked?: null | (() => void);
  collectiveColourEnergyValues: null | ColourEnergyValues;
  // onRefresh(): any;
}

/**
 * The Summary Card template is used to display the profile
 * picture, name and colour energies of the people associated to
 * the context of its placement e.g. In a meeting tab.
 */
function SummaryCard({
  loggedInUserName,
  loggedInUserConfirmedDate,
  loggedInUserColourEnergy,
  title = 'Colour energy summary',
  subtext = '',
  people = [],
  error,
  theme,
  conscious,
  onSuccessConsent,
  onConsciousToggleClicked,
  collectiveColourEnergyValues,
}: // onRefresh,
SummaryCardProps) {
  const { t } = useTranslation();

  const onToggleClicked = () => {
    if (onConsciousToggleClicked) {
      onConsciousToggleClicked();
    }
  };

  return (
    <div
      className={`bg_${theme}_card border_${theme} summary_section_shadow card_padding mt-0 summary_section pt-4`}
      style={{ maxHeight: people.length > 8 ? 620 : 550 }}
    >
      <div className="d-flex flex-column">
        {error ? (
          <Error
            error={error}
            consentScope="User.Read.All People.Read People.Read.All Chat.ReadBasic ChannelMember.Read.All Presence.Read Presence.Read.All Contacts.Read"
            successCallback={onSuccessConsent}
            theme={theme || 'default'}
          />
        ) : (
          <div>
            <div className="d-flex flex-column flex-md-row justify-content-between">
              <div className="d-flex flex-column mb-2 mb-md-0">
                <h3 className={`text_colour_${theme} section_title`}>
                  {title}
                </h3>
                <span className={`text_colour_${theme} default_sub_title`}>
                  {subtext}
                </span>
              </div>
              {onConsciousToggleClicked && collectiveColourEnergyValues ? (
                <div className="d-flex flex-row my-2 my-md-0">
                  <p
                    className={`ps-0 ps-md-1 summaryCard-toggle-text text_colour_${theme} ${
                      !conscious ? 'summaryCard-toggle-text-selected' : ''
                    }`}
                    style={{ marginBottom: 0 }}
                  >
                    {t('ms-teams-toggle-less-conscious')}
                  </p>
                  <div className="mt-1">
                    <CustomToggle
                      value={conscious || false}
                      onClick={onToggleClicked}
                      theme={theme || 'default'}
                    />
                  </div>
                  <p
                    className={`summaryCard-toggle-text text_colour_${theme} ${
                      conscious ? 'summaryCard-toggle-text-selected' : ''
                    }`}
                    style={{ marginBottom: 0, marginLeft: 1 }}
                  >
                    {t('ms-teams-toggle-conscious')}
                  </p>
                </div>
              ) : null}
            </div>
            <div className="insights_person-card-container">
              {people
                .slice(0, 50)
                .map(
                  ({
                    displayName,
                    name,
                    profilePhoto,
                    status,
                    consciousColourEnergy,
                    lessConsciousColourEnergy,
                    allowShareColourEnergies,
                    colourEnergiesConfirmedAt,
                    consciousColourEnergyValues,
                    lessConsciousColourEnergyValues,
                    email,
                  }: Person) => {
                    let colourScoreValues = consciousColourEnergy;

                    if (onConsciousToggleClicked) {
                      colourScoreValues = conscious
                        ? consciousColourEnergy
                        : lessConsciousColourEnergy;
                    }

                    const aboveLineIndex = Object.values(
                      (conscious
                        ? consciousColourEnergyValues
                        : lessConsciousColourEnergyValues) || {},
                    )
                      .sort((a: number, b: number) => b - a) // sort to largest -> smallest
                      .findIndex((c) => c < 50);

                    return (
                      <PersonCard
                        theme={theme || 'default'}
                        loggedInUserName={loggedInUserName}
                        loggedInUserConfirmedDate={loggedInUserConfirmedDate}
                        loggedInUserColours={loggedInUserColourEnergy}
                        displayName={displayName || name || ''}
                        email={email}
                        profilePhoto={profilePhoto}
                        status={status}
                        colourEnergies={
                          allowShareColourEnergies ? colourScoreValues : null
                        }
                        colourEnergiesConfirmedAt={colourEnergiesConfirmedAt}
                        aboveLineIndex={aboveLineIndex}
                      />
                    );
                  },
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SummaryCard;
