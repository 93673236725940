/* eslint-disable max-len */
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hotjar } from 'react-hotjar';
import { app } from '../../services/ms-teams-service';
import OuterTemplate from './OuterTemplate';
import ColourEnergiesExist from './Steps/ColourEnergiesExist';
import SetupColourEnergies from './Steps/SetupColourEnergies';
import InsightsLogoWhite from '../common/InsightsLogoWhite/InsightsLogoWhite';
import '../../util/i18n';
import {
  getSignedInUser,
  postSupportRequestBotMessage,
} from '../../services/learnersService';
import BlueSwish from '../../images/BlueSwish.svg';
import getPrivacyLink from '../../util/privacyPolicyLink';
import { SignedInUser, User } from '../../global/types';
import RequestSent from './Steps/RequestSent';
import Spinner from '../common/Spinners/Spinner';

// import sendEmail from '../../services/emailService';
/**
 * Allows the user to progress through each step
 * of the flow setup.
 * Steps:
 * Start (confirm email address)
 * Setup colour energies/ filled OR Colour energies exist
 */
function InsightsFlow() {
  const queryParameters = new URLSearchParams(window.location.search);
  const languageCode = queryParameters.get('lng');
  const lng = (languageCode && languageCode.slice(0, 2)) || 'en';
  const { t } = useTranslation('translation', { lng });
  const [curStep, setCurStep] = useState<ReactNode>();
  const [loading, setLoading] = useState<boolean>(true);
  const [privacyUrl, setPrivacyUrl] = useState<string>('');
  const [theme, setTheme] = useState('default');

  let userDetails = {} as User;

  const handlePrivacyLink = async () => {
    const url = await getPrivacyLink();
    setPrivacyUrl(url);
  };

  const fetchLearner = async () => {
    const teamsAppData = await app.getContext();
    setTheme(teamsAppData.app.theme);
    let tenantId = '';

    if (teamsAppData.user?.tenant?.id) tenantId = teamsAppData.user?.tenant?.id;

    const user = await getSignedInUser(tenantId);
    setLoading(false);

    if (user.status === 200) {
      // user has exisiting record in DB
      const userData = user.data as SignedInUser;

      const details: User = {
        id: userData.id,
        organisationName: userData.organisationName,
        displayName: userData.displayName,
        email: userData.userPrincipalName || userData.email,
        createdAt: userData.createdAt,
        pronoun: userData.pronoun,
        chapters: userData.chapters,
        consciousColourEnergyValues: userData.consciousColourEnergyValues,
        consciousColourEnergy: userData.consciousColourEnergy,
        lessConsciousColourEnergyValues: userData.lessConsciousColourEnergyValues,
        lessConsciousColourEnergy: userData.lessConsciousColourEnergy,
        colourEnergiesConfirmedAt: userData.colourEnergiesConfirmedAt,
        energyCodeToName: userData.energyCodeToName,
        allowShareColourEnergies: false,
        settings: null,
        userStatementSettings: null,
        theme: 'default',
        error: null,
        isNominated: userData.isNominated,
        hasNominatePeople: userData.hasNominatePeople,
        tenantId,
        languageCode: teamsAppData.app.locale,
        fullProfile: false,
        profileViews: userData.profileViews,
        highlights: [],
        strengths: [],
        weakness: [],
        communicationDos: [],
        communicationDonts: [],
        insightsForThisWeek: [],
      };

      userDetails = details;
      return details;
    }

    return null;
  };

  const postProfileRequest = async (requestType: string) => {
    const teamsAppData = await app.getContext();
    const upn = teamsAppData.user?.userPrincipalName || '';
    const { email, tenantId, displayName } = userDetails;
    const { data } = await getSignedInUser(tenantId);

    if (email || data.userPrincipalName || upn) {
      const result = await postSupportRequestBotMessage(
        displayName || data.displayName,
        email || data.userPrincipalName || upn,
        tenantId,
        requestType,
      );

      hotjar.event('profile-issue-message-sent-to-reps');

      setCurStep(
        <RequestSent
          error={
            result.status === 200
              ? ''
              : t('ms-teams-send-request-error-subtext')
          }
        />,
      );
    } else {
      setCurStep(
        <RequestSent error={t('ms-teams-send-request-error-subtext')} />,
      );
    }
  };

  const checkExistingColourEnergies = async () => {
    const learner = await fetchLearner();
    const teamsAppData = await app.getContext();

    const hasExistingEnergies = learner && learner.consciousColourEnergy;
    const userEmail = learner && learner.email;

    if (!hasExistingEnergies) {
      setLoading(false);
      setCurStep(
        <SetupColourEnergies
          hasNominatePeople={learner && learner.hasNominatePeople}
          emailAddress={userEmail || teamsAppData.user?.userPrincipalName}
          postProfileRequest={postProfileRequest}
          lng={lng}
        />,
      );
    } else {
      const {
        // email, consciousColourEnergyValues, languageCode, displayName, createdAt, pronoun, chapters,
        email,
        consciousColourEnergyValues,
        consciousColourEnergy,
        displayName,
        createdAt,
        // pronoun,
        chapters,
      } = learner;
      setLoading(false);
      setCurStep(
        <ColourEnergiesExist
          displayName={displayName}
          colourEnergyValues={consciousColourEnergyValues}
          colourEnergyOrder={consciousColourEnergy}
          emailAddress={email || teamsAppData.user?.userPrincipalName || '---'}
          createdAt={createdAt}
          chapters={chapters}
          hasNominatedPeople={learner && learner.hasNominatePeople}
          postProfileRequest={postProfileRequest}
          lng={lng}
        />,
      );
    }
  };

  useEffect(() => {
    handlePrivacyLink();
    checkExistingColourEnergies();
  }, []);

  return (
    <div
      className={`insights insights_flow_border_colour_${theme}`}
      style={{
        backgroundImage: `url(${BlueSwish})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        borderTopWidth: '20px',
        borderTopStyle: 'solid',
      }}
    >
      {loading ? (
        <Spinner theme="default" animationName="spin" />
      ) : (
        <>
          <div className="col-12 col-sm-8 col-lg-6 d-flex flex-column justify-content-center align-items-center">
            <InsightsLogoWhite />
            <OuterTemplate content={curStep} />
          </div>
          <div className="d-flex flex-column align-items-center text-center mt-5 mb-4">
            <p className="fw-light">
              © The Insights Group Limited,
              {' '}
              {new Date().getFullYear()}
              . All
              rights reserved.
            </p>
            <a
              href={privacyUrl}
              target="_blank"
              style={{ textDecoration: 'none' }}
              rel="noreferrer"
            >
              {' '}
              Our Privacy notice
            </a>
          </div>
        </>
      )}
    </div>
  );
}

export default InsightsFlow;
