import React from 'react';
import { useTranslation } from 'react-i18next';
import { app, pages } from '../../services/ms-teams-service';
import InfoCard from '../common/InfoCard';
import ViewInApp from '../../images/ViewInApp.svg';
import AnalyticsBars from '../../images/AnalyticsBars.svg';
import AiPortraitImageSpark from '../../images/AiPortraitImageSpark.svg';
import Compare from '../../images/Compare.svg';
import LoveBubbleChat from '../../images/LoveBubbleChat.svg';
import SwitchAccount from '../../images/SwitchAccount.svg';
import ViewInAppContrast from '../../images/ViewInAppContrast.svg';
import AnalyticsBarsContrast from '../../images/AnalyticsBarsContrast.svg';
import AiPortraitImageSparkContrast from '../../images/AiPortraitImageSparkContrast.svg';
import CompareContrast from '../../images/CompareContrast.svg';
import LoveBubbleChatContrast from '../../images/LoveBubbleChatContrast.svg';
import SwitchAccountContrast from '../../images/SwitchAccountContrast.svg';
import { postBotInsightsMessage } from '../../services/learnersService';

interface InfoSectionProps {
  userEmail: string;
  theme: string;
  isProfileSet: boolean;
}

function InfoSection({
  userEmail,
  theme,
  isProfileSet = true,
}: InfoSectionProps) {
  const { t } = useTranslation();

  const triggerBotMessage = async () => {
    const teamsAppData = await app.getContext();
    if (teamsAppData.user?.tenant?.id && userEmail) {
      await postBotInsightsMessage(userEmail, teamsAppData.user?.tenant.id);
    }

    const clientId = process.env.REACT_APP_MICROSOFT_APP_ID;

    const userBotDeeplink = `https://teams.microsoft.com/l/chat/0/0?users=28:${clientId}`; // goes to the bot chat for the current user

    app.openLink(userBotDeeplink);
  };

  const InfoCardData = [
    {
      // 1 - View in-app profile summary
      id: 1,
      icon: theme !== 'contrast' ? ViewInApp : ViewInAppContrast,
      title: t(
        'ms-teams-insights-no-colour-energy-profile-info-view-in-app-title',
      ),
      description: t(
        'ms-teams-insights-no-colour-energy-profile-info-view-in-app-description',
      ),
      whereText: t('ms-teams-info-section-here-on-your-insights-dashboard'),
      whereLink: 'profileSummary',
      whereId: 'profileSummaryLink',
    },
    {
      // 2 - Group colour energy breakdowns
      id: 2,
      icon: theme !== 'contrast' ? AnalyticsBars : AnalyticsBarsContrast,
      title: t('ms-teams-insights-no-colour-energy-profile-info-group-title'),
      description: t(
        'ms-teams-insights-no-colour-energy-profile-info-group-description',
      ),
      whereText: t(
        'ms-teams-info-section-add-insights-tabs-on-meetings-chats-teams-to-view',
      ),
      whereButtonAction: triggerBotMessage,
    },
    {
      // 3 - Communication do’s and don’ts
      id: 3,
      icon: theme !== 'contrast' ? LoveBubbleChat : LoveBubbleChatContrast,
      title: t('ms-teams-insights-no-colour-energy-profile-info-comms-title'),
      description: t(
        'ms-teams-insights-no-colour-energy-profile-info-comms-description',
      ),
      whereText: t(
        'ms-teams-info-section-add-insights-tabs-on-meetings-chats-teams-to-view',
      ),
      whereSubtext: t(
        'ms-teams-info-section-open-the-insights-app-when-youre-in-a-meeting',
      ),
      whereButtonAction: triggerBotMessage,
    },
    {
      // 4 - Compare profile summaries
      id: 4,
      icon: theme !== 'contrast' ? Compare : CompareContrast,
      title: t(
        'ms-teams-insights-no-colour-energy-profile-info-comms-compare-title',
      ),
      description: t(
        'ms-teams-insights-no-colour-energy-profile-info-comms-compare-description',
      ),
      whereText: t(
        'ms-teams-info-section-add-insights-tabs-on-meetings-chats-teams-to-view',
      ),
      whereButtonAction: triggerBotMessage,
    },
    {
      // 5 - Insights Discovery colour wheel avatar generator
      id: 5,
      icon:
        theme !== 'contrast'
          ? AiPortraitImageSpark
          : AiPortraitImageSparkContrast,
      title: t(
        'ms-teams-insights-no-colour-energy-profile-info-comms-colour-wheel-title',
      ),
      description: t(
        'ms-teams-insights-no-colour-energy-profile-info-comms-colour-wheel-description',
      ),
      whereText: t(
        'ms-teams-info-section-access-the-avatar-tab-on-your-insights-dashboard',
      ),
      whereButtonAction: () => {
        if (pages.isSupported()) {
          pages.navigateToApp({
            appId: process.env.REACT_APP_TEAMS_APP_ID as string,
            pageId: process.env.REACT_APP_AVATAR_ENTITY_ID as string,
          });
        }
      },
    },
    {
      // 6 - Weekly My Insights
      id: 6,
      icon: theme !== 'contrast' ? SwitchAccount : SwitchAccountContrast,
      title: t(
        'ms-teams-insights-no-colour-energy-profile-info-comms-weekly-title',
      ),
      description: t(
        'ms-teams-insights-no-colour-energy-profile-info-comms-weekly-description',
      ),
      whereText: t('ms-teams-info-section-here-on-your-insights-dashboard'),
      whereLink: 'insightsWeekly',
      whereId: 'insightsWeeklyLink',
    },
  ];

  const generateOutput = () => InfoCardData.map((item) => {
    if (isProfileSet) {
      return (
        <InfoCard
          isLinkDisabled={item.id === 5}
          key={item.id}
          isProfileSet
          icon={item.icon}
          title={item.title}
          description={item.description}
          whereText={item.whereText}
          whereLink={item.whereLink || ''}
          whereSubtext={item.whereSubtext || ''}
          whereButtonAction={item.whereButtonAction}
          whereId={item.whereId || null}
        />
      );
    }
    return (
      <InfoCard
        isLinkDisabled={false}
        isProfileSet={false}
        icon={item.icon}
        title={item.title}
        description={item.description}
      />
    );
  });

  return (
    <div
      className={`col-lg-${
        isProfileSet ? '8' : '12'
      } col-12 noProfileSetInfoSection paddingLeft20`}
    >
      <div className="noProfileSetInfoSectionContainer" id="faqContainer">
        {generateOutput()}
      </div>
    </div>
  );
}

export default InfoSection;
