/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Avatar } from '@fluentui/react-components';
import _ from 'lodash';
import EnergySummary from '../ColourEnergy/EnergySummary';
import {
  TeamsStatus,
  ColourEnergyValues,
  ColourEnergyOrder,
} from '../../../global/types';
import NoEnergySummary from '../ColourEnergy/NoEnergySummary';
import CompareProfile from '../../CompareProfile';

interface PersonCardProps {
  loggedInUserName: string;
  loggedInUserConfirmedDate: string;
  loggedInUserColours: ColourEnergyValues;
  theme: string;
  displayName: string;
  email: string;
  profilePhoto: string;
  status: TeamsStatus;
  colourEnergies: ColourEnergyOrder | null;
  colourEnergiesConfirmedAt?: string;
  aboveLineIndex: number;
}

/**
 * The individual person cards display important information
 * such as MS teams profile picture, display name and coulour
 * energies.
 */
function PersonCard({
  loggedInUserName,
  loggedInUserConfirmedDate,
  loggedInUserColours,
  theme,
  displayName,
  email,
  profilePhoto = '',
  status = '',
  colourEnergies = null,
  colourEnergiesConfirmedAt = '',
  aboveLineIndex,
}: PersonCardProps) {
  const isMe = () => colourEnergiesConfirmedAt === loggedInUserConfirmedDate
    && displayName === loggedInUserName;
  const hasInsightsProfile = colourEnergiesConfirmedAt !== undefined && !_.isEmpty(loggedInUserColours);

  return (
    <div className={`insights_${theme}_card`}>
      <div className="d-flex align-items-center mb-2">
        <Avatar
          image={{ src: `data:image/jpeg;base64, ${profilePhoto}` }}
          name={displayName}
          badge={{ status: status || 'unknown' }}
          size={32}
        />
        {' '}
        <p data-hj-suppress className={`ms-2 mt-3 sub_${theme}_title`}>{displayName}</p>
      </div>
      <div
        style={{
          marginBottom: 'auto',
          height: '100%',
          minHeight: 100,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {colourEnergies && colourEnergiesConfirmedAt ? (
          <>
            <EnergySummary
              theme={theme}
              colourEnergies={colourEnergies}
              fullscreen
              aboveLineIndex={aboveLineIndex}
            />
            {isMe() || !hasInsightsProfile ? null : (
              <CompareProfile
                theme={theme}
                userEmail={email}
              />
            )}
          </>
        ) : (
          <NoEnergySummary theme={theme} />
        )}
      </div>
    </div>
  );
}

export default PersonCard;
